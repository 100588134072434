import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import './style.comp.css';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import ChartJsForPrediction from "./ChartJsForPrediction";

const mapStateToProps = (state) => {
    return {
        isFetching: state.data.isFetching,
    }
}

const formatRotationLabels = () => Array
    .from({ length: 12 }, (_, i) => `R${i + 1}`)
    .map(name => ({
        name,
        property: row => {
            const defaultValue = "No data";
            if (! row.rotations) return defaultValue;
            if (! row.rotations[name]) return defaultValue;

            const rotation = row.rotations[name];
            const percentage = row.available_long_distance_leads > 0
                ? rotation.count / row.available_long_distance_leads * 100
                : 0;
            return `${rotation.count} (${percentage.toFixed(1)}%)`;
        }
    }));


const charts = [
    {
        name: "Available headroom",
        subtitle: "Available headroom",
        property: "available_headroom",
        additionalCharts: [
            {
                name: "Flex",
                property: "flex"
            },
            {
                name: "Total headroom",
                property: "total_headroom"
            },
            {
                name: "Average headroom for last 30 minutes",
                property: "avg_headroom"
            }
        ],
        additionalLabels: [
            {
                name: "Total headroom",
                property: row => row.total.total_headroom
            },
            {
                name: "Total available headroom",
                property: row => row.total.available_headroom
            },
            {
                name: "Headroom utilization",
                property: row => `${row.total.headroom_utilization}%`
            }
        ]
    },
    {
        name: "Progression",
        subtitle: "Progression, which shows rising of the total headroom",
        property: "progression",
    },
    {
        name: "Available long distance leads",
        subtitle: "Available long distance leads ready for call",
        property: "available_long_distance_leads",
        additionalLabels: formatRotationLabels()
    },
    {
        name: "Master effect",
        subtitle: "Master effect value",
        property: "master_effect"
    },
    {
        name: "Queue",
        subtitle: "Queue size",
        property: "queue"
    },
    {
        name: "Predicted amount of potentially finished calls",
        subtitle: "Count of calls, which should be finished in next period regarding to average B leg call duration and call start time",
        property: "predicted_finished_calls_in_next_period",
        additionalCharts: [
            {
                name: "Finished calls",
                property: "finished_calls"
            }
        ]
    },
    {
        name: "Acceleration",
        subtitle: "Acceleration increases/decreases final headroom value",
        property: "acceleration",
    },
    {
        name: "Raw calls per period",
        subtitle: "Average count of raw calls per period",
        property: "raw_calls_per_period"
    },
    {
        name: "Transfers",
        subtitle: "Amount of transfers",
        property: "transfers"
    },
    {
        name: "Predicted headroom",
        subtitle: "Amount of headroom predicted using queue, available headroom, count of the potentially finished calls and average count of the raw calls per period",
        property: "predicted_headroom",
        additionalCharts: [
            {
                name: "Predicted headrooms from A leg",
                property: "a_leg_headroom"
            }
        ]
    },
    {
        name: "Velocity",
        subtitle: "Amount of calls, which should be made per period to close predicted headroom",
        property: "velocity",
        additionalCharts: [
            {
                name: "Calls per period",
                property: "calls_per_period"
            }
        ],
        additionalLabels: [
            {
                name: "Total calls",
                property: row => row.total.calls
            }
        ]
    },
    {
        name: "Velocity limiter",
        subtitle: "Maximum count of calls per second",
        property: "velocity_limiter",
        limiter: "velocity_max"
    },
    {
        name: "Agent velocity",
        subtitle: "Average count of calls per period for single agent. This value calculates using statistic",
        property: "agent_velocity"
    },
    {
        name: "Agents",
        subtitle: "Count of agents to close predicted headroom. Calculates using agent velocity",
        property: "agents",
        limiter: "agents_max",
        additionalCharts: [
            {
                name: "Online agents",
                property: "online_agents"
            },
            {
                name: "Online agents on call",
                property: "online_agents_on_call"
            }
        ]
    }
];

const Predictions = (props) => {
    const {isFetching} = props;
    const [chartData, setChartData] = useState([]);
    const [chartLimits, setChartLimits] = useState({ velocity_max: 0, agents_max: 0 });
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [ isLoading, setIsLoading ]= useState(false);

    const handleDayChange = (date) => {
        setDate(moment(date).format('YYYY-MM-DD'));
    };

    const fetchDataForSelectedDay = useCallback(async (soft = false) => {
        try {
            if (! soft) setIsLoading(true);
            const data = await axios
                .get(`/buffer/predictions?date=${date}`)
                .then(({data}) => data || [])
                .catch(error => {
                    if (error.response && error.response.data && typeof error.response.data === 'object') {
                        let warn = "";
                        for (const key in error.response.data) {
                            const message = error.response.data[key];
                            warn += message;
                        }

                        toast.warn(warn);
                    }

                    return [];
                });

            setChartLimits(data.limits);
            setChartData(data.data);
            if (! soft) setIsLoading(false);
        } catch (error) {
            toast.error('Data not available');
            console.log(error);
        }

    }, [date, isFetching]);

    useEffect(() => {
        fetchDataForSelectedDay();
    }, [fetchDataForSelectedDay]);

    return (
        <div className="chart-container">
            <div className="previous_dates-container" style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: "20px",
                padding: "20px"
            }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        label="Select date"
                        value={date}
                        onChange={handleDayChange}
                        maxDate={new Date()}
                        format="YYYY-MM-DD"
                        clearable
                        autoOk
                    />
                </MuiPickersUtilsProvider>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {
                    isLoading ? <h2 style={{ margin: '0 auto'}}>Loading....</h2> :
                    (charts.map( chart =>
                        <ChartJsForPrediction
                            key={chart.property}
                            data={chartData}
                            name={chart.name}
                            subtitle={chart.subtitle}
                            property={chart.property}
                            limiter={chart.limiter ? chartLimits[chart.limiter] : null}
                            additionalCharts={chart.additionalCharts || []}
                            additionalLabels={chart.additionalLabels || []}
                        />
                    ))
                }
            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default connect(mapStateToProps)(Predictions);
